.onlinestatusname{
    font-size: larger;
}

.greenColorHeading{
    color: green;
    font-size: larger;
}
.redColorHeading{
    color: red;
    font-size: larger;
}
