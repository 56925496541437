.main-login-container-lc {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.filter-section {
  margin-bottom: 20px;
  text-align: center;
}

.month-year-dropdown {
  padding: 10px;
  font-size: 16px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.summary-section {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  background: #f9f9f9;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.today-summary,
.login-month-summary {
  width: 45%;
}

.today-summary h3,
.login-month-summary h3 {
  margin-bottom: 10px;
  color: #333;
}

.summary-details p {
  margin: 5px 0;
}

.login-time-table-container {
  margin-top: 20px;
}

.login-time-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.login-time-table th,
.login-time-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.login-time-table th {
  background-color: #f2f2f2;
}

.no-data-message {
  text-align: center;
  font-size: 16px;
  color: #666;
}
.session-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

.session-head th {
  background-color: #f4f4f4;
  font-weight: bold;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.session-row td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.session-times {
  margin-top: 1em;
}

.session-col {
  text-align: left;
  padding: 8px;
}

.session-row:hover {
  background-color: #f9f9f9;
}

.allDatePopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: black;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
}

.allDatePopup-content {
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff4d4f;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.popup-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.popup-table th,
.popup-table td {
  border: 1px solid #ddd;
  padding: 8px;
  
  text-align: center;
}
