.confirm-button {
    border-width: 0px;
    width: 80px;
    height: 40px;
    background-color: blue;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    text-align: center;

}

.confirm-button:hover {
    cursor: pointer;

}

.popup-container-display {
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
    padding: 20px;
    height: 200px;
    width: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.trigger-button {
    border-width: 0px;
    background-color: #4f7195;
    padding: 10px;
    color: #fff;
    margin: 5px;
    border-radius: 5px;
}

.trigger-button:hover {
    cursor: pointer;
    background-color: #4913fa;
}

.confirm-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkMark {
    color: blue;
    font-size: 50px;
    padding: 10px;
}