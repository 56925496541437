.percentage-cancel{
    margin-top: 10px
}


.input-popup-password{
    margin-right: 10px;
    border-radius: 10px;
    height: 32px;
    border: 2px solid chocolate;
    text-align: center;
}

.perecentage-container{
    display: flex;
    justify-content: space-between;
}
.close{
    margin-top: 10px
}