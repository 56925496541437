.astrologers-image {
    object-fit: cover;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    align-self: center;
}

.astro-table-container {
    box-sizing: border-box;
    background-size: cover;
    border-collapse: collapse;
    overflow-y: scroll;
    width: 100%;
    height: 520px;
}

.astro-table {
    text-align: center;
    margin-left: 5px;
    width: 95%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 18px;
    background-size: cover;
}

.astro-table-container th,
.table-container td {
    width: calc(100% / 8);
}

.astro-he {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.astro-section1 {
    display: flex;
    width: 600px;
    margin-left: 1px;
}
.withdrawlRequest-color {
    color: red;
    font-weight: bold;
}

.astro-search-input {
    width: 30%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.search-container {
    display: flex;
    width: 50%;
    position: relative;
    flex-grow: 1;
    padding-right: 35px;
}

.body-details {
    padding: 10px;
}
.astro-rows {
    font-weight: 400;
}
.astro-rows:hover {
    background-color: rgb(255, 99, 52);
    color: #fbfbfb;
    font-weight: 400;
    cursor: pointer;
}

.atrologers-heading {
    text-align: center;
    align-items: center;
}

.astro-search-container {
    display: flex;
    width: 50%;
    flex-direction: row;
    position: relative;
    padding-right: 35px;
}

.astro-search-icon {
    position: absolute;
    margin-left: 10px;
    transform: translateY(-50%);
    color: #888;
    margin-top: 20px;
}

.astro-search-input {
    height: 20px;
    width: 500px;
    padding-left: 10px;
}

.astro-section1 {
    display: flex;
    width: 600px;
    margin-left: 20px;
}

.length {
    font-weight: 700;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    color: navy;
}

.body-details {
    padding: 10px;
}

.astro-bg {
    background-color: rgb(100, 100, 100);
    font-weight: 600;
    color: rgb(249, 187, 0);
}

.count-of-chats {
    display: flex;
    gap: 20px;
}
.length-container {
    display: flex;
    gap: 20px;
}
.length:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 3px;
}
