.astrologer-register-container {
    height: 100vh;
    padding-left: 10px;
}

.name {
    width: 180px;
    padding: 10px;
    border-bottom: 1px solid rgba(126, 173, 219, 0.5);
    border-top: 1px solid rgba(143, 184, 225, 0.5);
    border-left: 1px solid rgba(140, 194, 248, 0.5);
    background-color: rgb(224, 155, 155, 0.5);
    text-align: center;
}


.register-rows {
    background-color: rgb(100, 100, 100);
    font-weight: 600;
    color:rgb(249, 187, 0);
    height: 50px;
}


.Status-panel-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 50px;
}

.btn {
    border: solid 1px #000;
    border-width: 0px;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin: 5px;
    width: 120px;
}

.activeTabSection {
    background-color: #3f83d6;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.activeTabSectionTwo {
    background-color: #030303;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.loader-button {
    border-width: 0px;
    width: 80px;
    height: 40px;
    background-color: blue;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    display: flex;
    justify-content: center;
    border-radius: 5px;
}

.admin-controls {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-row:hover {
    background-color: #ded81cd6;
    color: #fff;
    cursor: pointer;
}

.register-astro-table-container{
    box-sizing: border-box;
    background-size: cover;
    border-collapse: collapse;
    overflow-y: scroll;
    width: 100%;
    height: 520px;
}

.register-details-rows{
    font-weight: 400;
    text-align: center;
    

}
.register-details-rows:hover {
    background-color: rgb(255, 99, 52);
    color: #fbfbfb;
    font-weight: 400;
    cursor: pointer;
}

.pending-register-details-rows{
    font-weight: 400;
    text-align: center;   
}
.pending-register-details-rows:hover{
    /* background-color: rgb(216, 216, 216); */
    color: #030303;
    font-weight: 400;
    cursor: pointer;
}

.register-astrologer-table {
    text-align: center;
    margin-left: 5px;
    width: 95%;
    border-collapse: collapse;
    margin-top:  20px;
    font-size: 18px;
    background-size: cover;
}
