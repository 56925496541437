.astro-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Light dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.astro-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 300px;
}
.wallet-date-time{
    font-size: 15px;
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.astro-confirm-button, .astro-cancel-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s;
}

.astro-confirm-button {
    background-color: #4CAF50; 
    color: white;
}

.astro-confirm-button:hover {
    background-color: #45a049;
}

.astro-cancel-button {
    background-color: #f44336;
    color: white;
}

.astro-cancel-button:hover {
    background-color: #e53935;
}
.edit-button{
    border-radius: 4px;
    cursor: pointer;
    height: 30px;
    width: 55px;
    font-size: 12px;
    transition: transform 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 10px;
}

.edit-button:hover{
    background-color: #007bff;
    color: #fff;
    border-color: #848484a4;
}


.refund-paragraph{
    color: #45a049;
    font-weight: bold;
    font-size: 16px;
}

.not-available-paragraph{
    font-size: 12px;
    color: #e53935;
    font-weight: bold;

}

.wlt-input{
    margin-bottom: 10px;
}