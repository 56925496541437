.user-details-container {
    padding: 20px;
    
    border-radius: 8px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    /* max-width: 1100px; */
    width: 80%;
    height: 90vh;
    margin: 0 auto;
    padding-top: 10px;
}

.user-info {
    text-align: center;
    margin-bottom: 0px;
}

.user-name {
    font-size: 25px;
    font-weight: bold;
    color: #ff8800;
    margin-bottom: 0px;
}

.user-phone {
    font-size: 20px;
    color: #343434;
}
.Username{
    color: #555;
}

.tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.tab-button {
    padding: 10px 20px;
    background-color: #dbe3d7c6;
    color: rgb(0, 0, 0);
    border: solid 1px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 15px;
    transition: transform 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 10px;

}

.tab-button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #848484a4;
    transform: scale(1.1);
}
.tab-button:hover{
    transform: scale(1.1);
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
}

.data-table th,
.data-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.data-table th {
    background-color: #f1f1f1;
    font-weight: bold;

}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 10px;
    margin-left: 10px
}

.pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination-info {
    font-size: 14px;
    color: #555;
}

.user-rows{
    text-align: center;
    align-items: center;
}

.user-back-button {
    height: 45px;
    width: 45px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
    transition: transform 0.3s ease; 
    text-align: center;    
    padding-top: 5px;

       
}
.user-back-button:hover {
    background-color: #003268;
    transform: scale(1.1);
}
.user-back-{
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.user-audio-container{
    height: 30px;
    max-width: 200px;
}