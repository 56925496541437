.payment-astro-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.payments-astro-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 20px 20px 20px 20px rgba(0.2, 0.2, 0.2, 0.2);
    text-align: center;
    width: 300px;
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.astro-confirm-button, .astro-cancel-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s;
}

.astro-confirm-button {
    background-color: #4CAF50; 
    color: white;
}

.astro-confirm-button:hover {
    background-color: #45a049;
}

.astro-cancel-button {
    background-color: #f44336;
    color: white;
}

.astro-cancel-button:hover {
    background-color: #e53935;
}

.messegeeee{
    color: black;
    align-items: center;
    text-align: center;
}