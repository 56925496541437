.call-item {
    padding: 12px;
}

.call-container {
    background-size: cover;
    padding-left: 30px;
}

.table-container {
    margin-top: 50px;
    margin-bottom: 50px;
    overflow-x: auto; 
}

.astro-table {
    width: 100%; 
    border-collapse: collapse; 
}

.header-column {
    background-color: #000;
    color: #fff; 
}

.call-heading {
    font-size: 30px;
    margin-bottom: 20px; 
}

.search-bar {
    width: 320px;
    height: 30px;
    border-radius: 10px;
    padding: 5px;
    border: solid 1px #d6cccc; 
    margin-bottom: 20px;
}

.astro-table th, .astro-table td {
    padding: 12px; 
    text-align: left; 
    border: 1px solid #d6cccc; 
}

.livecalls-table-rows:hover {
    background-color: rgb(255, 99, 52);
    color: #fbfbfb;
    font-weight: 400;
}
