.anvesh-astro-table-container {
  margin: 20px;
  overflow-x: auto; /* Horizontal scrolling for smaller screens */
  max-height: 490px; /* Define maximum height for the table container */
  border: 1px solid #ddd;
}

.anvesh-astro-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Fix column widths for better alignment */
}

.anvesh-astro-header {
  background-color: #f4f4f4;
  font-weight: bold;
}

.anvesh-astro-header th {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  top: 0;
}

.anvesh-astro-row {
  border-bottom: 1px solid #ddd;
}

.anvesh-astro-row td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

/* .anvesh-day-cell,
.anvesh-actions-cell,
.anvesh-time-slots-cell {
  vertical-align: top;
} */

.anvesh-no-preferred-times {
  color: #888;
  font-style: italic;
  padding: 5px 10px;
  text-align: center;
}

.anvesh-prrrrr-prrr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.anvesh-delete-btnbtn {
  padding: 5px;
  background-color: #ff2600de;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 30px;
  font-size: 12px;
}

.anvesh-delete-btnbtn:hover {
  background-color: #ff0000;
}

/* Attractive buttons for the time picker modal */
.anvesh-modal-actions button {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff; /* Blue color for primary buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.anvesh-modal-actions button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
}

/* Add spacing between the buttons */
.anvesh-modal-actions button:first-child {
  margin-right: 10px;
}

/* Modal styles */
.anvesh-time-picker-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  max-width: 400px;
  width: 90%;
  box-sizing: border-box;
}

.anvesh-time-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.anvesh-time-picker-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.anvesh-modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Smooth scrolling for the table container */
.anvesh-astro-table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.anvesh-astro-table-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.anvesh-astro-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.anvesh-astro-add-btn {
  padding: 8px 15px;
  background-color: #28a745; /* Green background */
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.anvesh-astro-add-btn:hover {
  background-color: #218838; /* Darker green on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add hover shadow effect */
}

.anvesh-astro-add-btn:active {
  background-color: #1e7e34; /* Even darker green when clicked */
  transform: scale(0.98); /* Slight scale effect */
}
