.confirm-button {
    border-width: 0px;
    width: 80px;
    height: 40px;
    background-color: blue;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    text-align: center;

}

.confirm-button:hover {
    cursor: pointer;
}

.popup-container-display {
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
    padding: 20px;
    height: 200px;
    width: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.trigger-button-reject {
    border-width: 0px;
    background-color: #ba5151;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.trigger-button-reject:hover {
    cursor: pointer;
    background-color: #ef3333;
}

.confirm-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rejectMark {
    color: red;
    font-size: 50px;
    padding: 10px;
}

.close-btn {
    background-color: transparent;
    border-width: 0px;
}

.button-container {
    display: flex;
    justify-content: end;
    width: 100%;
}

.close-btn:hover {
    cursor: pointer;
}