.chat-history-main-container{
    padding: 20px;
}

.chat-room {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 500px;
    background-color: #000; 
  
    background-repeat: repeat; 
    background-size: contain; 
    padding: 10px;
    border-radius: 10px;
  }
  
  
.chat-history-heading{
    text-align: center;
}
.empty-msg{
    color: #fff;
    text-align: center;
}
.chat-profile-container{
    display: flex;
  justify-content: space-between;
  padding: 10px;
}
.chat-profile-container p{
    font-weight: 700;
}