.cancel-x-container{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.cancel-icon-x{
    cursor: pointer;

}
.cancel-icon-x:hover{
    color: red;
    transition: calc(0.9s);
    transform: scale(1.4);

}