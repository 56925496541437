.astro-details-container {
    justify-content: center;  
    align-items: center;      
   
    padding: 10px;
    margin-top: 20px;
    height:100vh ; 
    overflow: scroll;
    overflow-y: scroll;      
}

.inside-container-astro-details {
    text-align: center;
    width: 100%;
           
}
.single-astro-rows:hover:hover {
    background-color: rgb(255, 99, 52);
    color: #fbfbfb;
    font-weight: 400;
    
}
.image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1); 
    background-color: #f5f5f5; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 0px;
}

.image-name{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    

}

.astro-name {
    font-size: 26px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;  
    text-transform: capitalize;
}
.astro-phone_number{
    font-size: 20px;
}
.astro-details-table {
    width: 700px;
    border-collapse: collapse;
    margin-bottom: 20px;
    border: 1px solid #ddd; 
}


.astro-details-table td {
    padding: 10px;
    border: 1px solid #a9a9a9; 
    text-align: center;      
}

.astro-properties {
    font-weight: bold;
    text-align: left;         
}

.astro-back-button {
    height: 45px;
    width: 45px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 30px;
    transition: transform 0.3s ease; 
    text-align: center;    
    padding-top: 5px;    
}
.active-button{
    padding: 10px 20px;
    background-color: #ddf0d5e0;
    color: rgb(0, 0, 0);
    border: solid 1px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 15px;
    transition: transform 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 10px;

}

.inactive-button{
    padding: 10px 20px;
    background-color: #e6cfcfcb;
    color: rgb(1, 1, 1);
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 15px;
    transition: transform 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.astro-back-button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
}

.active-button:hover {
    transform: scale(1.1);
}
.inactive-button:hover {
    transform: scale(1.1);
}

.astro-cnt{
    padding-left: 30px;
    padding-top: 10px;
}
.button-container{
    margin-right: 100px;
}

/*  */

.active-button:disabled {
    background-color: #33ff00;
    cursor: not-allowed;
    color: white;
}

.inactive-button:disabled{
    background-color: rgb(223, 0, 0);
    cursor: not-allowed;
    color: white;
}
.toggle-btn{
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    border-width: 0px;
}

.active-transaction-btn{
    background-color: #4514e6;
    color: #fff;
}
.transaction-filter-container-row{
    display: flex;
    width: 20%;

}
.total-amount{
    padding-left: 30px;
    font-size: 18px;
    width: 120px;
}
.edit-button{
    padding: 20px;
    margin-left: 20px;
}
.astrologer-details-waiting-view{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    
}
.astrologer-details-waiting-view h1{
    font-size: 24px;
}
.astrologer-details-waiting-view p{
    font-size: 18px;
    
}
.empty-text{
    text-align: center;
}
.main-bar-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.main-bar-sec h1{
    margin-left: 20px;
}
.view-stats:hover{
    background-color: #4514e6;
    color: #fff;
    border-width: 0px;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    width: 120px;
}
.view-stats{
    width: 120px;
    border-radius: 5px;
    padding: 5px;
    border-width: 0px;
    background-color: #a9a9a9;
}


.onlinestatusname{
    font-size: larger;
}

.greenColorHeading{
    color: green;
    font-size: larger;
}
.redColorHeading{
    color: red;
    font-size: larger;
}
