.profile-container {
    display: flex;

}

.row-container {
    display: flex;
    flex-direction: column;

}

.detailed-row {
    height: 50px;
    width: 380px;
    padding: 10px;

    border-top: 1px solid rgba(143, 184, 225, 0.5);
    border-left: 1px solid rgba(140, 194, 248, 0.5);
    background-color: #0f939a80;
    text-align: center;
    margin: 0px;
}

.detailed-column {
    height: 50px;
    width: 180px;
    padding: 10px;
    border-bottom: 1px solid rgba(11, 11, 11, 0.5);
    border-top: 1px solid rgba(143, 184, 225, 0.5);
    border-left: 1px solid rgba(140, 194, 248, 0.5);
    background-color: #0f939a80;
}

.detailed-table-container {
    margin-top: 50px;
    height: 100vh;
    margin-left: 20px;
}

.back-button {
    text-align: center;
    cursor: pointer;
    width: 50px;
    align-self: flex-start;
}


.register-astro-details-table {
    width: 700px;
    border-collapse: collapse;
    margin-bottom: 20px;
    border: 1px solid #adadad;
    margin-top: 20px; 
}


.register-astro-details-table td {
    padding: 10px;
    border: 1px solid #898989; 
    text-align: center;      
}
