

.selected-date-text{
    align-self: flex-start;
    width: fit-content;
}
.chats-count-container{
    display: flex;
    flex-direction: row;
    gap : 40px;
}

.astrologer-wallet-chat-date-clear-button {
    font-family: "Roboto";
    height: 45px;
    font-weight: lighter;
    width: 100px;
    font-size: 16px;
    padding: 5px;
    font-size: 18px;
    color: white;
    border-radius: 10px;
    border-style: none;
    background-color: #007bff;
}

.astrologer-wallet-chat-date-input {
    height: 40px;
    width: 200px;
    font-size: 16px;
    margin-right: 10px;
    font-family: "Roboto";
    font-weight: 200;
    padding-left: 10px;
}
.astrologer-wallet-chat-date-heading-name {
    font-family: "Roboto";
    align-self: left;
    width: fit-content;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0px;
}
.astrologer-wallet-chat-date-container {
    display: flex;
    justify-content: start;
    margin: 20px;
    margin-left: 0px;
    margin-top: 1px;
    font-family: "Roboto";
}
