/* Main Container for SaveNotification */
.notification-main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  height: 500px; /* Fixed height for the main container */
}

/* Save Notification Section (Left Side) */
.save-notification-section {
  width: 50%;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scrolling */
  height: 100%; /* Match the height of the main container */
}

.save-notification-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.notification-form-group {
  margin-bottom: 20px;
}

.notification-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
}

.notification-input,
.notification-textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
}

.notification-textarea {
  resize: none;
  height: 80px;
}

.add-notification-button,
.save-notification-button,
.remove-notification-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.add-notification-button {
  background-color: #007bff;
  color: #fff;
  margin-right: 10px;
}

.save-notification-button {
  background-color: #28a745;
  color: #fff;
}

.remove-notification-button {
  background-color: #dc3545;
  color: #fff;
  margin-top: 10px;
}

.add-notification-button:hover,
.save-notification-button:hover,
.remove-notification-button:hover {
  opacity: 0.9;
}

.user-type-selector {
  margin: 20px 0;
}

.notification-dropdown {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Notifications List Section (Right Side) */
.notifications-list-section {
  width: 55%;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scrolling */
  height: 100%; /* Match the height of the main container */
}

.notifications-list-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.notifications-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-item {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
}

.notification-item h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notification-item p {
  font-size: 14px;
  margin-bottom: 10px;
}

.delete-notification-button,
.edit-notification-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
}

.delete-notification-button {
  background-color: #dc3545;
  color: #fff;
}

.edit-notification-button {
  background-color: #007bff;
  color: #fff;
}

.delete-notification-button:hover,
.edit-notification-button:hover {
  opacity: 0.9;
}

.no-notifications-message {
  font-size: 14px;
  color: #777;
  text-align: center;
  margin-top: 20px;
}

/* Popup for Editing Notifications */
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content h3 {
  margin-bottom: 15px;
}

.popup-content label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.popup-content input,
.popup-content textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.popup-content button {
  padding: 8px 12px;
  border: none;
  
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.popup-content button:first-of-type {
  background-color: #28a745 !important; /* Green color */
  color: #fff !important;
  margin-right: 10px;
}


.popup-content button:last-child {
  background-color: #dc3545;
  color: #fff;
}

.popup-content button:hover {
  opacity: 0.9;
}
