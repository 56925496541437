.astrologer-chat-duration {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.astrologer-chat-duration h2 {
  text-align: center;
  margin-bottom: 20px;
}

.selectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.selectors label {
  margin-right: 10px;
  font-weight: bold;
}

.selectors select {
  padding: 5px;
  font-size: 14px;
}

.month-summary {
  margin-bottom: 20px;
}

.month-summary p {
  margin: 5px 0;
}

.day-table {
  width: 100%;
  border-collapse: collapse;
}

.day-table th,
.day-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 20%;
}

.day-table th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.loader {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #666;
}

.calendar {
  margin-top: 20px;
}

.calendar h3 {
  margin-bottom: 10px;
}

.day-table thead, .day-table tbody {
  display: table;
  width: 100%;
}

.day-table tbody {
  display: block;
  max-height: 350px;
  overflow-y: scroll;
  width: 100%;
}

.day-table tbody tr {
  display: table;
  width: 100%;
}

.day-table td, .day-table th {
  width: 33%; /* Adjust to ensure columns align */
  text-align: left;
  min-width: 150px; /* Ensure a minimum column width */
}

#d-rows {
  width: 100px;
}
