.payments-container{
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* 
.payments-table{
    width: 75%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-top: 20px;
} */
 

.astro-table-container {
    box-sizing: border-box;
    background-size: cover;
    border-collapse: collapse;
    overflow-y: scroll;
    width: 100%;
}

.pymnts-container{
    height: 440px;
}
.astro-table {
    text-align: center;
    margin-left: 5px;
    width: 95%;
    border-collapse: collapse;
    margin-top:  20px;
    font-size: 18px;
    background-size: cover;
}



.payments-table {
    width: 70%;
    border-collapse: collapse;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
}

.payments-table th,
.payments-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.payments-table th {
    background-color: #f1f1f1;
    font-weight: bold;

}
.total-and-reload-cnt{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.reload-icon{
    margin-top: 10px;
    cursor: pointer;
    margin-right: 70px
}
.reload-icon-2 {
    cursor: pointer;
    transition: transform 0.5s ease, color 0.2s ease; 
}

.reload-icon-2:hover {
    color: rgb(255, 255, 255);
    transform: scale(1.1) rotate(360deg); 
}


.reload-icon:hover{
    color: green;
    transition: calc(0.2s);
    transform: scale(1.1);
}

.filters {
    display: flex;
    gap: 20px; 
  }

.total-amount-payments{
    font-size: 17px;    
}


.filter-container-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;

}

.filter-button{
    border-radius: 4px;
    cursor: pointer;
    height: 30px;
    width: 55px;
    font-size: 12px;
    transition: transform 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 10px;

}
.active-filter{
    background-color: #007bff;
    color: #fff;
    border-color: #848484a4;
    transform: scale(1.1);
}

.margin-fliter{
    margin-top: 10px
}

.wlt-input{
    margin-bottom: 10px;
}