.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-container-display {
    position: relative;
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
    padding: 20px;
    height: 200px;
    width: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
}


.confirm-button {
    border-width: 0px;
    width: 80px;
    height: 40px;
    background-color: rgb(5, 99, 0);
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
    text-align: center;

}

.confirm-button:hover {
    cursor: pointer;

}

.confirm-details-onboard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}