.onboard-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: linear-gradient(135deg, #f7f7f7, #ffffff);
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .onboard-form h1 {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .form-group-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .form-subgroup {
    flex: 1;
    margin-right: 10px;
  }
  
  .form-subgroup:last-child {
    margin-right: 0;
  }
  
  .form-subgroup label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-subgroup input {
    width: 80%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px 15px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
  }
  
  .submit-btn:hover {
    background: #0056b3;
  }
  
  .submit-btn:active {
    background: #003d80;
  }

.label {
    color: #333;
    font-weight: 600;
}