.Dashbord-table-container {
    box-sizing: border-box;
    background-size: cover;
    border-collapse: collapse;
    overflow-y: scroll;
    width: 100%;
    height: 680px;
}

.dashboard-home-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    z-index: 1;
}

.hehe{
    font-size: 12px;
}
.dashbord-home-1{
    
    border-radius: 10px;
    padding: 24px;
    height: 435px;
    width: 420px;
    margin: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.head-section{
    background-color: #9a5858;
    padding: 5px 15px 10px 15px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.5);
    color: #fff;
}
.head-section p{
    font-weight: 700;
    font-size: 21px;
    color: #f2ebed;    
}
.header-section-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.header-section-1 p{
    font-weight: 600;
    font-size: 18px;
    color: #2c28ad;
}
.see-all-btn{
    background-color: blue;
    padding: 10px;
    border-width: 0px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
    font-weight: 500;
    font-size: 16px;
}
.see-all-btn:hover{
    transform: scale(1.06);
    background-color: #2403ff;
    transition: transform 0.3s ease-in-out;
}
.users-astro-table-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.users-astro-table {
    text-align: center;
    margin-left: 5px;
    width: 95%;
    border-collapse: collapse;
    margin-top:  20px;
    font-size: 18px;
    background-size: cover;
    margin-bottom: 10px;
}

.users-astro-he {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.users-astro-bg {
    background-color: rgb(100, 100, 100);
    font-weight: 600;
    color:rgb(249, 187, 0);
}
.users-astro-rows{
    font-weight: 400;  

}
.loader-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.horizontal-divider{
    width: 95%;
    height: 3px;
    background-color: #9a5858;
    border-width: 0px;
}