/* SuperAstrologers.css */

.super-astrologer-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.super-astrologer-popup-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.super-astrologer-popup-buttons {
    margin-top: 20px;
}

.super-astrologer-confirm-button, .super-astrologer-cancel-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.super-astrologer-confirm-button {
    background-color: #28a745; /* Green */
    color: white;
}

.super-astrologer-cancel-button {
    background-color: #dc3545; /* Red */
    color: white;
}

.super-astrologer-confirm-button:hover {
    background-color: #218838; 
}

.super-astrologer-cancel-button:hover {
    background-color: #c82333; /* Darker Red */
}

.super-astrologer-astro-back-button {
    margin-bottom: 20px;
    /* Add other styles for the back button if needed */
}
