.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:  #444;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.popup-content h2 {
  text-align: center;
}

.popup-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.popup-content th, .popup-content td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.popup-content button {
  padding: 5px 10px;
  margin: 5px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  height: 35px;
  width: 60px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #218838;
}

.popup-content form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-content label {
  font-size: 14px;
}

.popup-content input,
.popup-content textarea {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.popup-content .form-actions {
  display: flex;
  justify-content: space-between;
}

.status-message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.status-message.success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.status-message.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}
.popup-close-button {
  position: absolute; 
  top: 10px; 
  right: 320px;  
  z-index: -1; 
}



