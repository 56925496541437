/*  */
.main-container {
  display: flex;
  flex-direction: row;
}

.app-dashboard {
  width: 20%; 
  color: white;
  padding: 0px;
  box-sizing: border-box;
  padding-left: 20px;
}

.routes {
  padding-left: 20px;
  width: 96%;
}