/* ChatBubble.css */
.chat-bubble {
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    max-width: 60%;
    position: relative;
  }
  
  .sender {
    background-color: #e6f7ff;
    align-self: flex-start;
    border-radius: 10px 10px 10px 0;
    margin-left: 10px;
  }
  
  .receiver {
    background-color: #f0f0f0;
    align-self: flex-end;
    border-radius: 10px 10px 0 10px;
    margin-right: 10px;
  }
  
  .message-text {
    margin: 0;
    font-size: 16px;
  }
  
  .message-time {
    font-size: 12px;
    color: gray;
    display: inline;
    margin: 0;
  }
  .searching-bar{
    display: flex;
    align-items: center;
    width: 350px;
  }
  .date-bubble {
    background-color: aqua;
    display: inline-block; 
    margin: 10px auto; 
    padding: 5px 10px; 
    border-radius: 10px; 
    text-align: center; 
  }
  
  .message-text-date {
    color: #333; 
    font-size: 12px; 
    margin: 0; 
  }
  .chat-image {
    max-width: 150%;  
    max-height: 250px; 
    border-radius: 8px;
    margin-top: 10px;
  }
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  max-height: 90%;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: contain; 
  max-width: 400px; 
  max-height: 800px; 
}
.modal:hover {
  cursor: pointer;
}

.chat-bubble {
  position: relative;
}

.status-tick {
  font-size: 12px;
  margin-left: 2px;
  vertical-align: middle;
  color: gray;
   display: inline;
}

.read-status {
  color: blue;
}

.message-meta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  
}
 


