.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
}
.login-form-container{
    border: solid 1px #ddd;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgb(101, 97, 97);
}
.login-mobile{
    margin-right:5px;
    font-weight: 600;
}
.login-main-heading{
    font-size: 25px;
    margin-bottom: 30px;
    color: #837518;
}
.otp-label{
    margin-left: 85px;
    font-weight: 600;
}
.login-input-section{
    margin-bottom: 20px;
    margin-top: 5px;
    padding: 5px;
}
.login-submit-btn{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.login-submit-btn button{
    background-color: #1937e1;
    padding: 10px;
    border-width: 0px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}
.login-input-section input{
    border-radius: 5px;
    width: 200px;
    padding: 5px 0px 5px 12px;
    background-color: #d9dfe2;
    border-width: 0px;
    height: 30px;
    outline: none;
}