.coupon-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 50px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .form-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .coupon-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
  }
  
  .form-input[type="file"] {
    padding: 0;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .End-button{
    width: 100%;
    padding: 10px;
    background-color: #ac0808;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

  }

  .submit-button:hover {
    background-color: #45a049;
  }
  
  .coupon-container h1 {
    color: #333;
  }
  
  @media (max-width: 600px) {
    .coupon-container {
      padding: 15px;
    }
  
    .form-input {
      padding: 8px;
    }
  
    .submit-button {
      padding: 8px;
      font-size: 14px;
    }
  }
  
  .validity-offer-card{
   background-color: rgb(160, 118, 118,0.5);
   padding: 20px;
   border-radius: 15px;
  }
  .validity-heading{
    font-weight: 600;
    font-size: 28px;
  }
  .free-chat-heading{
    text-align: center;
    color: #54be1b;
  }
  .valids-container{
    margin-left: 25px;
    background-color: #fff;
    width: 350px;
    padding: 20px;
    border-radius: 10px ;
    margin-top: 30px;
  }
  .force-stop-btn{
    background-color: #5e4242;
    color: white;
    border-radius: 5px;
    padding: 10px;
    border-width: 0px;
    cursor: pointer;
  }
  /* anvesh festival coupons css */

  .expire-coupons-current-copuons-heading{
    font-size: 17px;
  }


  .coupon-offer-card {
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 15px;
    width: 280px;
    height: 150px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #ffffff;
    transition: background-color 0.2s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    align-items: center;
  }
  
  .coupon-offer-card:hover {
    background-color: #e9ecef;
  }


  .today-coupon-offer-card {
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 15px;
    width: 280px;
    height: 180px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #ffffff;
    transition: background-color 0.2s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .today-coupon-offer-card:hover {
    background-color: #e9ecef;
  }

  .upcoming-coupon-offer-card{
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 15px;
    width: 280px;
    height: 140px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #ffffff;
    transition: background-color 0.2s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .upcoming-coupon-offer-card:hover {
    background-color: #e9ecef;
  }

  .expireddd{
    color: red;
  }
  .today-c{
    color: green;
  }

  .nxt-coupon{
    color: orangered;
  }

.festivals-c-head{
  font-size: 30px;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.buttons-coupons-c{
  justify-content: space-between;
  display: flex;
}

.trigger-button{
  height: 25px;
  width: 80px;
  text-align: center;
  padding: 0px;
}