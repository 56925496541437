.livechats-container{
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.join-btn {
    
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font size as needed */
    border: none;
    border-radius: 5px; /* Optional: Add border-radius for rounded corners */
    background-color: #007BFF; /* Adjust background color as needed */
    color: white; /* Text color */
    cursor: pointer; /* Pointer cursor on hover */
    transition: transform 0.3s ease; /* Smooth transition for the scale effect */
}

.join-btn:hover {
    transform: scale(1.1); /* Increase size by 10% on hover */
}


.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 10px;
    padding: 5px 10px;
    border: none;
    background-color: #007BFF; /* Adjust color as needed */
    color: white;
    cursor: pointer;
    border-radius: 5px; /* Optional: Add border-radius */
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #ccc; /* Grey out the button */
    cursor: not-allowed; /* Change cursor for disabled button */
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3; /* Darker color on hover */
}

.move-button{
    background-color: #007BFF;
    padding: 10px;
    border-width: 0px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}
.chats-main-heading{
    text-align: center;
}
.view-chat-button{
    background-color: #007BFF;
    padding: 10px;
    border-width: 0px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}


.messeges-google-icon:hover{
    color: rgb(0, 0, 0);
    align-items: center;
    align-self: center;
    text-align: center;
    font-weight: bold;
    transform: scale(.1s);
}
.iconnn{
    text-align: center;
    align-self: center;
    display: flex;
    justify-content: center;
}

/* Container for the entire date picker component */
/* .date-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  } */
  
  /* Heading for the date picker */
  .date-picker-heading {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  /* Styling for the date input field */
  .date-input {
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #ccc;
    border-radius: 4px;
    outline: none;
    width: 250px;
    transition: border-color 0.3s ease;
  }
  .date-input-picker {
    padding: 8px;
    font-size: 1rem;
    border: 2px solid #ccc;
    border-radius: 4px;
    outline: none;
    width: 150px;
    transition: border-color 0.3s ease;
    height: 25px;
  }
  
  .date-input:focus {
    border-color: #007bff;
  }
  
  /* Styling for the selected date text */
  .selected-date-text {
    margin-top: 5px;
    font-size: 1.2rem;
    color: #555;
    font-weight: 500;
  }
  .date-clear{
    padding: 10px;
    cursor: pointer;
    margin-left: 10px;
  }
  .date-clear-button{
    padding: 12px;
    margin-left: 10px;
    background-color: #007BFF;
    border-width: 0px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
  }

/*  */
.tooltip {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.9);
    color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    z-index: 10;
    max-width: 300px;
    box-shadow: 0 4px 8px rgb(255, 255, 255);
    text-align: left;
    font-weight: 400;
  }
  
  .view-container:hover .tooltip {
    display: block;
  }
  
  .tooltip-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 5px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .tooltip-row:last-child {
    border-bottom: none;
  }
  
  .tooltip-name {
    font-size: 14px;
    flex: 1;
    text-align: left;
  }
  
  .tooltip-phone {
    font-size: 14px;
    flex: 1;
    text-align: right;
  }
  
  .view-button {
    background-color: #4caf50;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .view-button:hover {
    background-color: #45a049;
  }
  