.container {
    margin-left: -17%; 
    background-color:azure;
    width: 80vw; 
    box-sizing: border-box;
}
.main {
    display: flex;
    flex-direction: column;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: center;
    margin-left: 30px;
    height: 200px;
    overflow-y: scroll;
    height: 100%;
}


.he {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
}

.bg {
    background-color:navy;
    font-weight: 600;
    color:#ddd;
}

.table-container {
    width: 100%;
    border-collapse: collapse;
    overflow-y: scroll;
    overflow-x: scroll;
    width: 900px;
    height: 500px;
}

.table-container th,
  .table-container td {
    width: calc(100% / 6);
}

.search-container {
    display: flex;
    width:  50%;
    position: relative;
    flex-grow: 1;
    padding-right: 35px;
}

.search-input {
    width: 100%;
    padding: 10px;
    padding-left: 35px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
  }

.row {
    color: #888;
}

.section1 {
    display: flex;
    width: 600px;
    margin-left: 30px;
}

.length {
    font-weight: 700;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    color: navy;
}

.users-heading {
    width: 600px;
    font-size: 1.725rem;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: center;
    color: darkred;
}

