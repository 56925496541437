.main {
    /*width: 20%;*/
    padding-left: 10px;
    position: relative;
    height: 100%; 
    z-index: 1000;
}

.person-logo {
    background-color: green;
    color: #ddd;
    font-weight: bold;
    padding: 15px;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
    margin-right: 20px;
    border: 1px solid #192A3E;
}

.header {
    display: flex;
}

.vis {
    margin-bottom: -10px;
    color: #192A3E;
    font-weight: 600;

}

.vis-con {
    margin-top: 15px; 
}

.mail {
    color: #90A0B7;
    font-weight: 400;
}

.dashboard {
    font-size: 30px;
    font-weight: bold;
    /* color: rgb(36, 36, 39); */
    color: #192A3E;
    margin-top: 20px;
    margin-right:  0px;
}

.sidebar {
    display: flex;
}
.da {
    color: #192A3E;
    /* color: rgba(57, 57, 57, 0.838); */
    margin-top: 40px;
    font-weight: 600;
}

.side1 {
    display: flex;
}
.side1:hover{
    cursor: pointer;
}
.user1 {
    margin-top: 18px;
    margin-right: 20px;
    color: #192A3E;
    font-weight: 600;
}

.user11 {
    color: #192A3E;
    font-weight: 600;
}

.main-con {
    box-shadow: 5px 0px 7px rgba(0, 0, 0, 0.062);
    height: 100vh;
}

.nav-user {
    text-decoration: none;
}

.link {
    text-decoration: none;
}

.vis-con {
    margin-right: 1040px;
}

.link p:hover {
    color: blue;
}

.link.active p {
    color: blue;
}
.active-tab{
    color: #192cbd;
    
}

.main-container {
    display: flex;
    flex-direction: row;
  }
  
  .app-dashboard {
    width: 20%; 
    color: white;
    padding: 0px;
    box-sizing: border-box;
    padding-left: 20px;
  }
