.offer-main-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
}

.offer-list {
  margin-bottom: 30px;
}

.offer-card {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #ffffff;
  transition: background-color 0.2s;
}

.offer-card:hover {
  background-color: #e9ecef;
}

.form-title {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.button-group {
  display: flex;
  gap: 10px; /* Add some space between buttons */
}

.submit-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.cancel-button {
  padding: 10px 15px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.expire-button,
.edit-button {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.expire-button {
  background-color: #dc3545;
  color: white;
}

.expire-button:hover {
  background-color: #c82333;
}

