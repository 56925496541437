/* Main container for the astrologer chat duration table */
.astrologer-chat-duration {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.selectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.selectors div {
  display: flex;
  flex-direction: column;
}

.selectors label {
  margin-bottom: 8px;
  font-weight: bold;
}

.selectors select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}


.month-summary {
  margin-top: 20px;
}

.month-summary p {
  font-size: 16px;
  margin-bottom: 8px;
}

.month-summary strong {
  font-weight: bold;
}

/* Table container */
.day-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensure consistent column widths */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.day-table th,
.day-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  width: 30%;/* Set a minimum width for the columns */
}

.day-table th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  width: 20px;
  z-index: 1; /* Ensure headers appear above rows */
}

.day-table thead, .day-table tbody {
   width: 50%; 
}

.day-table tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

.day-table thead {
  display: table;
   width: 50%; 
}

/* Loader styling */
.loader {
  text-align: center;
  font-size: 18px;
  padding: 20px;
  font-weight: bold;
  color: #007bff;
}

/* Styling for calendar and daily details section */
.calendar {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calendar h3 {
  text-align: center;
  margin-bottom: 10px;
}

.calendar p {
  font-size: 14px;
  margin: 8px 0;
  text-align: center;
}

.calendar p strong {
  font-weight: bold;
}
